<template>
  <div class="person-create-page container" v-if="currentAccount">
    <h1>Create person</h1>
    <hr />

    <form @submit.prevent="create">
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" id="name" v-model="name" />
      </div>
      <div class="mb-3">
        <label for="phone" class="form-label">Phone</label>
        <input type="text" class="form-control" id="phone" v-model="phone" />
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input type="text" class="form-control" id="email" v-model="email" />
      </div>
      <div class="mb-3">
        <label for="companyName" class="form-label">Company name</label>
        <input type="text" class="form-control" id="companyName" v-model="companyName" />
      </div>
      <div class="mb-3">
        <label for="status" class="form-label">Resident status</label>
        <select class="form-select" id="status" v-model="status">
          <option value="default" disabled>Select status</option>
          <option v-for="personStatus in personStatusList" :value="personStatus">{{ getStatusTranslate(personStatus) }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label class="form-label">Languages</label>
        <div class="row">
          <template v-for="(lang, index) in langs">
            <div class="card col-md-2 mb-3 ms-3 flex flex-row">
              <div class="card-body">
                <p class="card-text">{{ lang }}</p>
              </div>
              <span class="close"><i class="fa fa-times" title="Remove" @click="() => removeLang(index)"></i></span>
            </div>
          </template>
          <div class="new-lang col-md-2">
            <SelectWithSearch :clearOnSelect="true" :items="availableLangs" @search="onLangSearch" />
          </div>
        </div>
      </div>

      <div class="col-md-12 d-flex flex-wrap">
        <div class="mb-3 me-3 form-check">
          <label for="canCrossBorder" class="form-check-label">Can't cross border</label>
          <input type="checkbox" class="form-check-input" id="canCrossBorder" v-model="cantCrossBorder" />
        </div>
        <div class="mb-3 me-3 form-check">
          <label for="canCrossCanadianBorder" class="form-check-label">Can cross canadian border</label>
          <input type="checkbox" class="form-check-input" id="canCrossCanadianBorder" v-model="canCrossCanadianBorder" />
        </div>
        <div class="mb-3 me-3 form-check">
          <label for="canCrossMexicanBorder" class="form-check-label">Can cross mexican border</label>
          <input type="checkbox" class="form-check-input" id="canCrossMexicanBorder" v-model="canCrossMexicanBorder" />
        </div>
        <div class="mb-3 me-3 form-check">
          <label for="hasTsaCard" class="form-check-label">TSA card</label>
          <input type="checkbox" class="form-check-input" id="hasTsaCard" v-model="hasTsaCard" />
        </div>
        <div class="mb-3 me-3 form-check">
          <label for="hasTwicCard" class="form-check-label">TWIC card</label>
          <input type="checkbox" class="form-check-input" id="hasTwicCard" v-model="hasTwicCard" />
        </div>
        <div class="mb-3 me-3 form-check">
          <label for="hasHazmatCert" class="form-check-label">HAZMAT certificate</label>
          <input type="checkbox" class="form-check-input" id="hasHazmatCert" v-model="hasHazmatCert" />
        </div>
      </div>

      <div class="mb-3 col-md-12 home-location">
        <label for="homeLocation" class="form-label">Home location</label>
        <div class="d-flex flex-wrap align-items-center">
          <SelectWithSearch
            :inputValue="homeLocation?.postalCode"
            :items="homeLocations"
            @search="onHomeLocationSearch"
            @clear="onHomeLocationClear"
          />
          <span class="ms-3">{{ homeLocation?.formattedAddress }}</span>
        </div>
      </div>

      <div class="col-md-12 d-flex flex-wrap">
        <div class="mb-3 me-3 col-md-4">
          <label class="form-label">Who hired?</label>
          <div>
            <SelectWithSearch
              :inputValue="hiredByAccount?.login"
              :items="hiredByAccounts"
              @search="onHiredBySearch"
              @clear="onHiredByClear"
            />
          </div>
        </div>

        <div class="mb-3 me-3 col-md-4">
          <label for="hiredAt" class="form-label">When hired?</label>
          <input type="date" class="form-control" id="hiredAt" v-model="hiredAt" />
        </div>
      </div>

      <button class="btn btn-primary" :disabled="creating">
        <template v-if="!creating">Create</template>
        <template v-else><i class="fas fa-circle-notch fa-spin"></i></template>
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectWithSearch from '@/components/SelectWithSearch';
import { PersonStatus, PersonLang } from 'fifty-seven-llc-common';
import { personService } from '@/modules/person/person.service';
import { createPerson, getAccountList, getLocationList } from '@/api';

export default {
  name: 'PersonCreatePage',
  components: { SelectWithSearch },
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
    personStatusList() {
      return { ...PersonStatus };
    },
    personLangList() {
      return { ...PersonLang };
    },
    availableLangs() {
      return [...Object.keys(PersonLang)]
        .filter(i => !this.langs.find(j => j === i))
        .map(i => ({
          title: i,
          onClick: () => this.addLang(i),
        }));
    },
  },
  data: () => ({
    name: '',
    phone: '',
    email: '',
    status: 'default',
    langs: [],
    cantCrossBorder: false,
    canCrossCanadianBorder: false,
    canCrossMexicanBorder: false,
    hasTsaCard: false,
    hasTwicCard: false,
    hasHazmatCert: false,

    homeLocations: [],
    homeLocation: null,

    hiredAt: null,
    hiredByAccounts: [],
    hiredByAccount: null,

    companyName: '',

    langs: [],

    creating: false,
  }),
  methods: {
    getStatusTranslate(status) {
      return personService.getStatusTranslate(status);
    },

    onLangSearch() {},

    async addLang(lang) {
      this.langs.push(lang);
    },

    removeLang(index) {
      this.langs.splice(index, 1);
    },

    async onHomeLocationSearch(search) {
      const response = await getLocationList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.homeLocations = response.data.locations.map(i => ({
        title: i.formattedAddress,
        onClick: () => this.setHomeLocation(i),
      }));
    },

    onHomeLocationClear() {
      this.setHomeLocation(null);
    },

    setHomeLocation(location) {
      this.homeLocation = location;
    },

    async onHiredBySearch(search) {
      const response = await getAccountList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.hiredByAccounts = response.data.accounts.map(i => ({
        title: i.login,
        onClick: () => this.setHiredBy(i),
      }));
    },

    onHiredByClear() {
      this.setHiredBy(null);
    },

    setHiredBy(account) {
      this.hiredByAccount = account;
    },

    async create() {
      if (this.creating) {
        return;
      }

      if (!this.name) {
        return this.$toast.error("Name can't be empty.");
      }

      if (this.status === 'default') {
        return this.$toast.error('Select resident status.');
      }

      if (!this.langs.length) {
        return this.$toast.error('Select at least one language.');
      }

      try {
        this.creating = true;

        const response = await createPerson({
          name: this.name?.trim(),
          phone: this.phone?.trim() || undefined,
          email: this.email?.trim() || undefined,
          status: this.status,
          langs: this.langs,
          canCrossBorder: !this.cantCrossBorder,
          canCrossCanadianBorder: this.canCrossCanadianBorder,
          canCrossMexicanBorder: this.canCrossMexicanBorder,
          hasTsaCard: this.hasTsaCard,
          hasTwicCard: this.hasTwicCard,
          hasHazmatCert: this.hasHazmatCert,
          homeLocationId: this.homeLocation?.id || undefined,
          hiredAt: this.hiredAt || undefined,
          hiredBy: this.hiredByAccount?.id || undefined,
          companyName: this.companyName?.trim() || undefined,
        });
        if (response.errors) {
          throw new Error(response.errors[0].message);
          return;
        }

        this.$toast.success('Person successful created.', { duration: 2000 });
        setTimeout(() => this.$router.push({ name: 'persons' }), 2000);
      } catch (err) {
        this.$toast.error(err.message);
        this.creating = false;
      }
    },
  },
  mounted() {
    this.creating = false;
  },
};
</script>

<style scoped>
.person-create-page {
  padding: 2em 0;
}

.card {
  color: #212529;
}
</style>
