import { PersonStatus } from 'fifty-seven-llc-common';

class PersonService {
  getStatusTranslate(status) {
    switch (status) {
      case PersonStatus.NONE:
        return 'None';
      case PersonStatus.GREEN_CARD:
        return 'Green card';
      case PersonStatus.USA_BORN:
        return 'USA born';
      case PersonStatus.USA_PASSPORT:
        return 'USA passport';
      case PersonStatus.WORK_AUTHORIZATION:
        return 'Work authorization';
      default:
        return 'Unknown';
    }
  }
}

export const personService = new PersonService();
